export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },

  breadcrumbHome: {
    id: 'price_guide_product_model_breadcrumb_home',
    defaultMessage: 'Home',
  },

  breadcrumbPriceGuidesHub: {
    id: 'price_guide_product_model_breadcrumb_price_guides_hub',
    defaultMessage: 'Price guides',
  },

  title: {
    id: 'price_guide_product_model_title',
    defaultMessage:
      '{productModel} price guide {dateMonthLongCapitalized} {dateYearNumeric}',
  },

  subtitle: {
    id: 'price_guide_product_model_subtitle',
    defaultMessage:
      'In {dateMonthLongCapitalized} {dateYearNumeric}, the price of a(n) {productModel} starts at {startingPrice}, but prices vary based on storage, color, condition and carrier compatibility.',
  },

  metaTitle: {
    id: 'price_guide_product_model_meta_title',
    defaultMessage:
      'How much does the {productModel} cost? | {dateMonthLongCapitalized} {dateYearNumeric}',
  },

  bestOffersTitle: {
    id: 'price_guide_best_offers_title',
    defaultMessage: '{productModel} options on Back Market',
  },

  bestOffersSubtitle: {
    id: 'price_guide_best_offers_subtitle',
    defaultMessage:
      'Straight from our marketplace, curated for you from our seller’s listings.',
  },

  highlightTitle: {
    id: 'price_guide_highlight_title',
    defaultMessage: 'How much does the {productModel} cost?',
  },

  highlightSubtitle: {
    id: 'price_guide_highlight_subtitle',
    defaultMessage:
      'At perfect working condition, it’ll be up to 70% less than brand new.',
  },
} as const
