<template>
  <RevCard class="px-40 py-24">
    <RevContentBlock
      :button-label="i18n(translations.readMore)"
      button-variant="secondary"
      :hasRoundedImage="true"
      :image-props="{
        alt: '',
        height: 252,
        width: 448,
        src: '/img/price-guide/refurbished.svg',
      }"
      :title="i18n(translations.title)"
      title-tag="h2"
      title-variant="title2"
      :to="readMoreLink"
    >
      <p class="body-1">
        {{ i18n(translations.description) }}
      </p>
    </RevContentBlock>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { CMS } from '~/scopes/cms/routes-names'

import { useRouteLocationWithLocale } from '../../../../composables/useRouteLocationWithLocale'

import translations from './WhyRefurbished.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const readMoreLink = resolveLocalizedRoute({
  name: CMS.ARTICLE,
  params: {
    category: 'news',
    pageName: 'used-vs-refurbished',
  },
})
</script>
