<template>
  <RevCardGrid v-if="highlightCardData" :cards="highlightCardData">
    <template #default="{ card }: { card: HighlightCard }">
      <RevCard class="w-full p-16" v-bind="card">
        <div class="flex h-full flex-col items-center space-y-16">
          <RevTag
            class="body-1-bold w-fit self-start"
            :label="i18n(highlightCardConfigs[card.tag].tagLabel)"
            :variant="highlightCardConfigs[card.tag].tagVariant"
          />
          <RevIllustration
            :alt="props.productModel"
            class="p-16"
            :class="{ 'opacity-35': card.tag === HighlightCardTagType.NEW }"
            :height="192"
            :src="props.highlight.image.url"
            :width="192"
          />
          <h2 class="heading-2 text-center max-[480px]:min-h-[56px]">
            {{ i18n(highlightCardConfigs[card.tag].title) }}
          </h2>
          <div
            class="rounded-sm flex w-full flex-col items-center px-12 py-8"
            :class="highlightCardConfigs[card.tag].priceComponentColor"
          >
            <p class="caption">{{ i18n(translations.startingAt) }}</p>
            <p class="heading-2">{{ i18n.price(card.price) }}</p>
          </div>
          <div
            v-if="card.tag === HighlightCardTagType.NEW"
            class="flex justify-center"
          >
            <RevTooltip class="z-10" position="bottom-start">
              <template #trigger="{ show, hide }">
                <button
                  class="body-2-link"
                  @focusin="show"
                  @focusout="hide"
                  @mouseenter="show"
                  @mouseleave="hide"
                >
                  {{ i18n(translations.howIsPriceFoundLabel) }}
                </button>
              </template>
              <p class="body-2">
                {{ i18n(translations.howIsPriceFoundTooltip) }}
              </p>
            </RevTooltip>
          </div>
          <div
            v-if="card.tag === HighlightCardTagType.REFURBISHED && savedAmount"
            class="flex flex-wrap justify-center gap-x-4 gap-y-2"
          >
            <RevTag
              :label="
                i18n(translations.priceDifference, {
                  priceDiff: i18n.price(savedAmount),
                })
              "
              variant="success"
            />
            <p class="body-2-bold text-center">
              {{ i18n(translations.savedOrMore) }}
            </p>
          </div>
        </div>
      </RevCard>
    </template>
  </RevCardGrid>
  <div class="mt-24 flex w-full justify-center">
    <RevButton
      class="min-w-320 px-16 py-12"
      :to="toProductPageCanonical"
      variant="primary"
      @click="onClick"
    >
      {{
        i18n(translations.viewProductPageButtonLabel, {
          productModel: productModel,
        })
      }}
    </RevButton>
  </div>
</template>

<script setup lang="ts">
import { useRequestURL, useRouter } from '#imports'
import { type ComputedRef, computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { Highlight } from '@backmarket/http-api/src/api-specs-content/models/price-guide'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevCardGrid } from '@ds/components/CardGrid'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag, type Variants as RevTagVariants } from '@ds/components/Tag'
import { RevTooltip } from '@ds/components/Tooltip'

import { transformHref } from '~/scopes/cms/helpers/link/transformHref'

import translations from './Highlight.translations'

const props = defineProps<{ highlight: Highlight; productModel: string }>()

const i18n = useI18n()
const currentUrl = useRequestURL()
const router = useRouter()

const savedAmount: ComputedRef<Price | null> = computed(() => {
  if (
    props.highlight &&
    props.highlight.priceNew.currency === props.highlight.price.currency
  ) {
    return {
      amount: (
        parseFloat(props.highlight.priceNew.amount) -
        parseFloat(props.highlight.price.amount)
      ).toString(),
      currency: props.highlight.price.currency,
    }
  }

  return null
})

const toProductPageRelative = computed(() => {
  return transformHref({
    href: props.highlight.productPageLink.href,
    currentUrl,
  })
})

const toProductPageCanonical = computed(() => {
  // Per SEO requirements, indexation robots must be presented the canonical URL of PP (without query params or hash).
  return toProductPageRelative.value.href.split('?')[0]
})

function onClick() {
  // Open link with grade pre-selected to (real) user.
  router.push(toProductPageRelative.value.href)
}

enum HighlightCardTagType {
  NEW = 'new',
  REFURBISHED = 'refurbished',
}

const highlightCardConfigs = computed(() => ({
  [HighlightCardTagType.NEW]: {
    title: translations.cardNewTitle,
    tagLabel: translations.newTagLabel,
    tagVariant: 'secondary' as RevTagVariants,
    priceComponentColor: 'bg-static-default-mid',
  },
  [HighlightCardTagType.REFURBISHED]: {
    title: translations.cardRefurbishedTitle,
    tagLabel: translations.refurbishedTagLabel,
    tagVariant: 'primary' as RevTagVariants,
    priceComponentColor: 'bg-static-info-max',
  },
}))

type HighlightCard = {
  image: { [key: string]: string }
  description: string
  tag: HighlightCardTagType
  price: Price
}

const highlightCardData = computed(() => [
  {
    image: props.highlight.image,
    description: props.productModel,
    tag: HighlightCardTagType.NEW,
    price: props.highlight.priceNew,
  },
  {
    image: props.highlight.image,
    description: props.productModel,
    tag: HighlightCardTagType.REFURBISHED,
    price: props.highlight.price,
  },
])
</script>
