<template>
  <RevTable
    v-if="columns"
    :collection="collection"
    :columns="columns"
    transparent-header
  >
    <template
      v-for="grade in columnHeaders"
      :key="grade"
      #[`body-${grade}`]="{ item }"
    >
      <BestOffersTableCell :cell="item[grade]" />
    </template>
  </RevTable>
</template>

<script setup lang="ts">
import { useRequestURL } from '#imports'
import { computed } from 'vue'

import type { BestOffersTable } from '@backmarket/http-api/src/api-specs-content/models/price-guide'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTable } from '@ds/components/Table'

import { transformHref } from '~/scopes/cms/helpers/link/transformHref'

import BestOffersTableCell from '../BestOffersTableCell/BestOffersTableCell.vue'

import translations from './BestOffersTable.translations'

const props = defineProps<BestOffersTable>()
const i18n = useI18n()

const currentUrl = useRequestURL()

const columnsGradesLabels: { [key: string]: I18nDefinition } = {
  ECO: translations.columnTitleEco,
  VERY_GOOD: translations.columnTitleVeryGood,
  FLAWLESS: translations.columnTitleFlawless,
}

const columns = computed(() => {
  const cols = []
  cols.push({
    key: 'storage',
    label: '',
  })
  for (const header of props.columnHeaders) {
    cols.push({
      key: header,
      label: i18n(columnsGradesLabels[header]),
    })
  }

  return cols
})

const collectionData = computed(() =>
  props.collections.map((collection) =>
    collection.map((cell) =>
      cell
        ? {
            ...cell,
            productPageLink: transformHref({
              href: cell.productPageLink.href,
              currentUrl,
            }),
          }
        : null,
    ),
  ),
)

const collection = computed(() => {
  const rows = []
  for (const [rowIndex, element] of collectionData.value.entries()) {
    // RevTable collection cell data can be arbitrary value.
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const row: Record<string, any> = {
      id: rowIndex,
      storage: i18n(translations.rowTitleStorage, {
        storage: props.rowHeaders[rowIndex],
      }),
    }
    for (const [colIndex, cell] of element.entries()) {
      row[props.columnHeaders[colIndex]] = cell
    }
    rows.push(row)
  }

  return rows
})
</script>
