export default {
  title: {
    id: 'price_guide_best_offers_whats_difference_title',
    defaultMessage:
      "What's the difference between fair, good, and excellent condition?",
  },

  content: {
    id: 'price_guide_best_offers_whats_difference_content',
    defaultMessage:
      'Fair: signs of wear, Good: light signs of wear, and Excellent: barely there or perfect. This describes the appearance of the device — you’ll otherwise be getting a 100% functional device, guaranteed.',
  },
} as const
