export default {
  title: {
    id: 'price_guide_model_out_of_stock_title',
    defaultMessage: 'This is currently out of stock',
  },
  content: {
    id: 'price_guide_model_out_of_stock_content',
    defaultMessage:
      'Check back soon or add it to Favorites to get alerts for when it comes back.',
  },
  buttonLabel: {
    id: 'price_guide_model_out_of_stock_go_back',
    defaultMessage: 'Go back to {productModel}',
  },
} as const
