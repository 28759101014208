export default {
  rowTitleStorage: {
    id: 'price_guide_best_offers_table_row_title_storage',
    defaultMessage: '{storage} GB storage',
  },
  columnTitleEco: {
    id: 'price_guide_best_offers_table_column_title_eco',
    defaultMessage: 'Fair condition',
  },
  columnTitleVeryGood: {
    id: 'price_guide_best_offers_table_column_title_very_good',
    defaultMessage: 'Good condition',
  },
  columnTitleFlawless: {
    id: 'price_guide_best_offers_table_column_title_flawless',
    defaultMessage: 'Excellent condition',
  },
} as const
