export default {
  title: {
    id: 'price_guide_model_does_not_exist_title',
    defaultMessage:
      "The {productModel} is out of stock for now, but check back soon for when we've got it.",
  },
  buttonLabel: {
    id: 'price_guide_model_does_not_exist_check_similar_guides',
    defaultMessage: 'Check similar guides',
  },
} as const
