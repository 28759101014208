export default {
  newTagLabel: {
    id: 'price_guide_highlight_card_new_tag',
    defaultMessage: 'New',
  },
  refurbishedTagLabel: {
    id: 'price_guide_highlight_card_refurbished_tag',
    defaultMessage: 'Refurbished',
  },
  cardNewTitle: {
    id: 'price_guide_highlight_card_new_title',
    defaultMessage: "Price when it's new",
  },
  cardRefurbishedTitle: {
    id: 'price_guide_highlight_card_refurbished_title',
    defaultMessage: 'Price on Back Market',
  },
  startingAt: {
    id: 'price_guide_highlight_cards_starting_at_price',
    defaultMessage: 'Starting at',
  },
  viewProductPageButtonLabel: {
    id: 'price_guide_highlight_view_product_page_button_label',
    defaultMessage: 'View {productModel}',
  },
  howIsPriceFoundLabel: {
    id: 'price_guide_highlight_card_new_how_price_found',
    defaultMessage: 'How is this price found?',
  },
  howIsPriceFoundTooltip: {
    id: 'price_guide_highlight_card_new_how_price_found_tooltip',
    defaultMessage:
      'This price is given by the seller and corresponds either to the manufacturer’s recommended new price or to the average new price found at various distributors.',
  },
  priceDifference: {
    id: 'price_guide_highlight_card_refurbished_price_difference_save',
    defaultMessage: 'Save {priceDiff}',
  },
  savedOrMore: {
    id: 'price_guide_highlight_card_refurbished_price_difference_or_more',
    defaultMessage: 'or more vs. new',
  },
} as const
