<template>
  <RevCard class="px-40 py-24">
    <RevContentBlock
      :button-label="i18n(translations.buttonLabel)"
      :hasRoundedImage="true"
      :image-props="{
        alt: '',
        height: 252,
        width: 448,
        src: '/img/price-guide/products_not_found.svg',
      }"
      :title="i18n(translations.title, { productModel })"
      title-tag="h2"
      title-variant="title2"
      :to="priceGuidesHubLink"
    />
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { useRouteLocationWithLocale } from '../../../../composables/useRouteLocationWithLocale'
import { priceGuidesHubLinkDefinition } from '../../constants'

import translations from './OutOfStockProductDoesNotExist.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

defineProps<{
  productModel: string
}>()

const priceGuidesHubLink = resolveLocalizedRoute(priceGuidesHubLinkDefinition)
</script>
