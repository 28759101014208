<template>
  <RevInfoBlock
    :content="i18n(translations.content)"
    :icon="IconInfo"
    :title="i18n(translations.title)"
    variant="info"
  />
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconInfo } from '@ds/icons/IconInfo'

import translations from './WhatsDifferenceGrades.translations'

const i18n = useI18n()
</script>
