<template>
  <RevButton
    v-if="cell"
    size="small"
    :to="toProductPageCanonical"
    variant="secondary"
    @click="onClick"
    >{{ i18n.price(cell.price) }}</RevButton
  >
  <p v-else class="body-2">
    {{ i18n(translations.outOfStock) }}
  </p>
</template>

<script lang="ts" setup>
import { useRouter } from '#imports'
import { computed } from 'vue'

import type { Cell } from '@backmarket/http-api/src/api-specs-content/models/price-guide'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import translations from './BestOffersTableCell.translations'

const props = defineProps<{
  cell: Cell | null
}>()

const i18n = useI18n()
const router = useRouter()

const toProductPageCanonical = computed(() => {
  if (props.cell) {
    // Per SEO requirements, indexation robots must be presented the canonical URL of PP (without query params or hash).
    return props.cell.productPageLink.href.split('?')[0]
  }

  return null
})

function onClick() {
  if (props.cell) {
    // Open link with grade pre-selected to (real) user.
    router.push(props.cell.productPageLink.href)
  }
}
</script>
