<template>
  <RevInfoBlock
    :content="i18n(translations.content)"
    :cta="i18n(translations.buttonLabel, { productModel })"
    :icon="IconWarning"
    :title="i18n(translations.title)"
    variant="warning"
    @click="onClick"
  />
</template>

<script setup lang="ts">
import { useRequestURL, useRouter } from '#imports'

import type { ProductPageLink } from '@backmarket/http-api/src/api-specs-content/models/price-guide'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { IconWarning } from '@ds/icons/IconWarning'

import { transformHref } from '~/scopes/cms/helpers/link/transformHref'

import translations from './OutOfStockProductExists.translations'

const i18n = useI18n()
const currentUrl = useRequestURL()
const router = useRouter()

const props = defineProps<{
  productModel: string
  fallbackProductPageLink: ProductPageLink
}>()

const onClick = () => {
  router.push(
    transformHref({
      href: props.fallbackProductPageLink.href,
      currentUrl,
    }).href,
  )
}
</script>
